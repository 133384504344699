import React from 'react';
import IndexView from 'views/IndexView';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';
import { Helmet } from 'react-helmet';

const title = 'Home';

 const IndexPage = () => {
   return (
    <>
    <Helmet
      defaultTitle="WindRate | #1 Cloud Storage Pricing Engine"
      title={title}
      titleTemplate="%s | WindRate.com"
    />     
     <WithLayout
       component={IndexView}
       layout={Main}
     />
     </>
   )
 };
 
 export default IndexPage;
 