import React from 'react';
// import { useTheme, useMediaQuery, colors } from '@material-ui/core';
import { SearchResults } from 'components/windrate';
import { makeStyles } from '@material-ui/styles';
import { 
  SectionAlternate, 
  Section,
//  SectionAlternate2,
  } from 'components/organisms';
import { QuoteForm, Hero, Providers, Subscription, YouTubeVideoModal } from 'components/windrate';

import pagedata from "content/pages/index.yaml";
import subscription from "content/global/subscription.yaml";
// import { GpsFixed } from '@material-ui/icons';
const data = require('content/global/searchdata.json');

const hero = pagedata.hero;
const providers = pagedata.providers; 
const quoteForm = pagedata.quoteForm;

const useStyles = makeStyles(theme => ({
  sectionAlternate: {
    background: 'transparent',
    backgroundImage: `linear-gradient(180deg, ${theme.palette.background.paper} 400px, ${theme.palette.primary.dark} 0%)`,
    // backgroundImage: `linear-gradient(180deg, ${theme.palette.background.paper} 60%, ${theme.palette.primary.dark} 0%)`,
  },
  sectionAlternateEx: {
    background: 'transparent',
    backgroundImage: `linear-gradient(180deg, ${theme.palette.background.paper} 40%, ${theme.palette.primary.dark} 0%)`,
  },
  lessPadding: {
    paddingTop: '3rem !important',
    paddingBottom: '3rem !important',
  },   
}));

const IndexView = ({ themeMode }) => {
  const classes = useStyles();
  // const theme = useTheme();
  /*
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const scrollTo = id => {
    setTimeout(() => {
      const element = document.querySelector(`#${id}`);
      if (!element) {
        return;
      }

      window.scrollTo({ left: 0, top: element.offsetTop, behavior: 'smooth' });
    });
  };  
  */
  return (
    <div>
      <YouTubeVideoModal />
      <Hero data={hero} />
      <SectionAlternate className={classes.sectionAlternate}>
          <QuoteForm data={quoteForm} show_extended_fields={false}/>
      </SectionAlternate>
      <Section className={classes.lessPadding}>
        {/*
        <SearchResults 
            location='any'
            provider='any'
            data={data}
          />
        */}
          <Providers data={providers} />
      </Section>
      <SectionAlternate innerNarrowed className={classes.sectionAlternateEx}>
          <Subscription data={subscription} />
      </SectionAlternate>
    </div>
  );
}

export default IndexView;
